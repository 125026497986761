<template>
    <div>
        <landing-header></landing-header>
        <main class="static-page">
            <div class="m-3 p-3 text-center">
                <h4>Privacy Policy</h4>
            </div>
            <section class="p-5">
                <DIV id="page_1">


<DIV id="id1_1">
<!-- <P class="p2 ft4">Privacy Policy</P> -->
<P class="p3 ft1">Last updated: June 11, 2021</P>
<P class="p4 ft5">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</P>
<P class="p5 ft5">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <A href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/">Privacy Policy Generator.</A></P>
<P class="p6 ft4">Interpretation and Definitions</P>
<P class="p7 ft6">Interpretation</P>
<P class="p8 ft5">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</P>
<P class="p9 ft6">Definitions</P>
</DIV>
<DIV id="id1_2">
<P class="p0 ft1">For the purposes of this Privacy Policy:</P>
</DIV>
</DIV>
<DIV id="page_2">


<P class="p10 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">Account means a unique account created for You to access our Service or parts of our Service.</SPAN></P>
<P class="p11 ft10"><SPAN class="ft1">●</SPAN><SPAN class="ft9">Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to FTD Logistics Limited Liability Company, 1 Bassie Ogamba, Off Adeniran Ogunsanya, Surulere, Lagos.</SPAN></P>
<P class="p12 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</SPAN></P>
<P class="p13 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Country refers to: Nigeria</SPAN></P>
<P class="p14 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</SPAN></P>
<P class="p15 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">Personal Data is any information that relates to an identified or identifiable individual.</SPAN></P>
<P class="p16 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Service refers to the Website.</SPAN></P>
<P class="p17 ft15"><SPAN class="ft1">●</SPAN><SPAN class="ft14">Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to </SPAN><NOBR>third-party</NOBR> companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</P>
</DIV>
<DIV id="page_3">


<P class="p18 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</SPAN></P>
<P class="p19 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Website refers to FTD Logistics, accessible from </SPAN><A href="https://www.freeprivacypolicy.com/live/ftdlogistics.com">ftdlogistics.com</A></P>
<P class="p20 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</SPAN></P>
<P class="p21 ft16">Collecting and Using Your Personal Data</P>
<P class="p22 ft6">Types of Data Collected</P>
<P class="p23 ft17">Personal Data</P>
<P class="p24 ft5">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</P>
<P class="p25 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Email address</SPAN></P>
<P class="p26 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">First name and last name</SPAN></P>
<P class="p27 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Phone number</SPAN></P>
<P class="p27 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Address, State, Province, ZIP/Postal code, City</SPAN></P>
</DIV>
<DIV id="page_4">


<P class="p28 ft1">● Usage Data</P>
<P class="p29 ft17">Usage Data</P>
<P class="p22 ft1">Usage Data is collected automatically when using the Service.</P>
<P class="p30 ft5">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</P>
<P class="p31 ft5">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</P>
<P class="p32 ft18">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</P>
<P class="p22 ft17">Tracking Technologies and Cookies</P>
<P class="p33 ft5">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</P>
</DIV>
<DIV id="page_5">


<P class="p34 ft15"><SPAN class="ft1">●</SPAN><SPAN class="ft14">Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</SPAN></P>
<P class="p35 ft20"><SPAN class="ft1">●</SPAN><SPAN class="ft19">Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available at </SPAN><NOBR><A href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects</A></NOBR><A href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"> </A><NOBR><A href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">-flash.html#main_Where_can_I_change_the_settings_for_disabling</A></NOBR><A href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"> __or_deleting_local_shared_objects_</A></P>
<P class="p36 ft15"><SPAN class="ft1">●</SPAN><SPAN class="ft14">Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and </SPAN><NOBR>single-pixel</NOBR> gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for</P>
</DIV>
<DIV id="page_6">


<P class="p37 ft1">example, recording the popularity of a certain section and verifying</P>
<P class="p38 ft1">system and server integrity).</P>
<P class="p39 ft5">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: <A href="https://www.freeprivacypolicy.com/blog/cookies/">Cookies: What Do They Do?.</A></P>
<P class="p40 ft1">We use both Session and Persistent Cookies for the purposes set out below:</P>
<P class="p41 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">Necessary / Essential Cookies Type: Session Cookies Administered by: Us</SPAN></P>
<P class="p42 ft15">Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</P>
<P class="p43 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">Cookies Policy / Notice Acceptance Cookies Type: Persistent Cookies</SPAN></P>
<P class="p44 ft1">Administered by: Us</P>
</DIV>
<DIV id="page_7">


<P class="p45 ft8">Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</P>
<P class="p46 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">Functionality Cookies Type: Persistent Cookies Administered by: Us</SPAN></P>
<P class="p47 ft10">Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to <NOBR>re-enter</NOBR> your preferences every time You use the Website.</P>
<P class="p48 ft5">For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</P>
<P class="p3 ft6">Use of Your Personal Data</P>
<P class="p49 ft1">The Company may use Personal Data for the following purposes:</P>
<P class="p50 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">To provide and maintain our Service, including to monitor the usage of our Service.</SPAN></P>
<P class="p51 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to</SPAN></P>
</DIV>
<DIV id="page_8">


<P class="p52 ft8">different functionalities of the Service that are available to You as a registered user.</P>
<P class="p53 ft10"><SPAN class="ft1">●</SPAN><SPAN class="ft9">For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</SPAN></P>
<P class="p54 ft15"><SPAN class="ft1">●</SPAN><SPAN class="ft14">To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</SPAN></P>
<P class="p55 ft10"><SPAN class="ft1">●</SPAN><SPAN class="ft9">To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</SPAN></P>
<P class="p56 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">To manage Your requests: To attend and manage Your requests to Us.</SPAN></P>
<P class="p57 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets,</SPAN></P>
</DIV>
<DIV id="page_9">


<P class="p58 ft12">whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</P>
<P class="p59 ft10"><SPAN class="ft1">●</SPAN><SPAN class="ft9">For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</SPAN></P>
<P class="p60 ft1">We may share Your personal information in the following situations:</P>
<P class="p61 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</SPAN></P>
<P class="p62 ft10"><SPAN class="ft1">●</SPAN><SPAN class="ft9">For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</SPAN></P>
<P class="p63 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other</SPAN></P>
</DIV>
<DIV id="page_10">


<P class="p37 ft1">subsidiaries, joint venture partners or other companies that We</P>
<P class="p38 ft1">control or that are under common control with Us.</P>
<P class="p64 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.</SPAN></P>
<P class="p65 ft12"><SPAN class="ft1">●</SPAN><SPAN class="ft11">With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</SPAN></P>
<P class="p66 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">With Your consent: We may disclose Your personal information for any other purpose with Your consent.</SPAN></P>
<P class="p67 ft6">Retention of Your Personal Data</P>
<P class="p68 ft5">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</P>
<P class="p69 ft5">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</P>
</DIV>
<DIV id="page_11">


<P class="p0 ft6">Transfer of Your Personal Data</P>
<P class="p70 ft21">Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</P>
<P class="p71 ft22">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</P>
<P class="p72 ft21">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</P>
<P class="p9 ft6">Disclosure of Your Personal Data</P>
<P class="p23 ft17">Business Transactions</P>
<P class="p73 ft5">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</P>
</DIV>
<DIV id="page_12">


<P class="p0 ft17">Law enforcement</P>
<P class="p74 ft5">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</P>
<P class="p3 ft17">Other legal requirements</P>
<P class="p75 ft18">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</P>
<P class="p76 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Comply with a legal obligation</SPAN></P>
<P class="p26 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Protect and defend the rights or property of the Company</SPAN></P>
<P class="p77 ft8"><SPAN class="ft1">●</SPAN><SPAN class="ft7">Prevent or investigate possible wrongdoing in connection with the Service</SPAN></P>
<P class="p78 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Protect the personal safety of Users of the Service or the public</SPAN></P>
<P class="p27 ft1"><SPAN class="ft1">●</SPAN><SPAN class="ft13">Protect against legal liability</SPAN></P>
<P class="p29 ft6">Security of Your Personal Data</P>
<P class="p79 ft5">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</P>
</DIV>
<DIV id="page_13">


<DIV id="id13_1">
<P class="p0 ft4">Children's Privacy</P>
<P class="p80 ft21">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</P>
<P class="p81 ft5">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</P>
<P class="p82 ft4">Links to Other Websites</P>
<P class="p83 ft5">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</P>
<P class="p84 ft22">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</P>
<P class="p9 ft4">Changes to this Privacy Policy</P>
</DIV>
<DIV id="id13_2">
<P class="p0 ft22">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</P>
</DIV>
</DIV>
<DIV id="page_14">


<P class="p85 ft5">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</P>
<P class="p86 ft5">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</P>
<P class="p82 ft4">Contact Us</P>
<P class="p9 ft1">If you have any questions about this Privacy Policy, You can contact us:</P>
<P class="p87 ft1">● By email: info@ftdlogistics.com</P>
<P class="p88 ft23">Privacy Policy for FTD Logistics</P>
</DIV>
            </section>
        </main>
        <landing-footer></landing-footer>
    </div>
</template>
<script>
import footerVue from '../layout/footer.vue'
import headerVue from '../layout/header.vue'
export default {
    name: "PrivacyPolicy",
    components: {landingHeader: headerVue, landingFooter: footerVue}
}
</script>
<style scoped>
/* #page_1 {position:relative; overflow: hidden;margin: 95px 0px 146px 96px;padding: 0px;border: none;width: 720px;} */
#page_1 #id1_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_1 #id1_2 {border:none;margin: 33px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}





#page_2 {position:relative; overflow: hidden;margin: 91px 0px 111px 151px;padding: 0px;border: none;width: 665px;}





#page_3 {position:relative; overflow: hidden;margin: 90px 0px 111px 96px;padding: 0px;border: none;width: 720px;}





#page_4 {position:relative; overflow: hidden;margin: 95px 0px 187px 96px;padding: 0px;border: none;width: 720px;}





#page_5 {position:relative; overflow: hidden;margin: 90px 0px 109px 151px;padding: 0px;border: none;width: 568px;}





#page_6 {position:relative; overflow: hidden;margin: 95px 0px 157px 96px;padding: 0px;border: none;width: 720px;}





#page_7 {position:relative; overflow: hidden;margin: 91px 0px 117px 96px;padding: 0px;border: none;width: 720px;}





#page_8 {position:relative; overflow: hidden;margin: 91px 0px 110px 151px;padding: 0px;border: none;width: 561px;}





#page_9 {position:relative; overflow: hidden;margin: 90px 0px 140px 96px;padding: 0px;border: none;width: 720px;}





#page_10 {position:relative; overflow: hidden;margin: 95px 0px 143px 96px;padding: 0px;border: none;width: 720px;}





#page_11 {position:relative; overflow: hidden;margin: 94px 0px 152px 96px;padding: 0px;border: none;width: 720px;}





#page_12 {position:relative; overflow: hidden;margin: 94px 0px 168px 96px;padding: 0px;border: none;width: 720px;}





#page_13 {position:relative; overflow: hidden;margin: 93px 0px 89px 96px;padding: 0px;border: none;width: 720px;}
#page_13 #id13_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_13 #id13_2 {border:none;margin: 31px 0px 0px 0px;padding: 0px;border:none;width: 621px;overflow: hidden;}





#page_14 {position:relative; overflow: hidden;margin: 90px 0px 492px 96px;padding: 0px;border: none;width: 720px;}





.ft0{font: bold 18px 'Arial';color: #221341;line-height: 21px;}
.ft1{font: 18px 'Arial';color: #49396a;line-height: 21px;}
.ft2{font: bold 18px 'Arial';color: #553df4;margin-left: 13px;line-height: 21px;}
.ft3{font: bold 18px 'Arial';color: #553df4;line-height: 21px;}
.ft4{font: bold 34px 'Arial';color: #221341;line-height: 40px;}
.ft5{font: 18px 'Arial';color: #49396a;line-height: 33px;}
.ft6{font: bold 26px 'Arial';color: #221341;line-height: 30px;}
.ft7{font: 18px 'Arial';color: #49396a;margin-left: 13px;line-height: 35px;}
.ft8{font: 18px 'Arial';color: #49396a;line-height: 35px;}
.ft9{font: 18px 'Arial';color: #49396a;margin-left: 13px;line-height: 38px;}
.ft10{font: 18px 'Arial';color: #49396a;line-height: 38px;}
.ft11{font: 18px 'Arial';color: #49396a;margin-left: 13px;line-height: 37px;}
.ft12{font: 18px 'Arial';color: #49396a;line-height: 37px;}
.ft13{font: 18px 'Arial';color: #49396a;margin-left: 13px;line-height: 21px;}
.ft14{font: 18px 'Arial';color: #49396a;margin-left: 13px;line-height: 39px;}
.ft15{font: 18px 'Arial';color: #49396a;line-height: 39px;}
.ft16{font: bold 34px 'Arial';color: #221341;line-height: 53px;}
.ft17{font: bold 21px 'Arial';color: #221341;line-height: 24px;}
.ft18{font: 18px 'Arial';color: #49396a;line-height: 31px;}
.ft19{font: 18px 'Arial';color: #49396a;margin-left: 13px;line-height: 40px;}
.ft20{font: 18px 'Arial';color: #49396a;line-height: 40px;}
.ft21{font: 18px 'Arial';color: #49396a;line-height: 34px;}
.ft22{font: 18px 'Arial';color: #49396a;line-height: 32px;}
.ft23{font: 15px 'Arial';color: #6a6f82;line-height: 17px;}

.p0{text-align: left;margin-top: 0px;margin-bottom: 0px;}
.p1{text-align: left;padding-left: 24px;margin-top: 2px;margin-bottom: 0px;}
.p2{text-align: left;margin-top: 27px;margin-bottom: 0px;}
.p3{text-align: left;margin-top: 31px;margin-bottom: 0px;}
.p4{text-align: left;padding-right: 112px;margin-top: 20px;margin-bottom: 0px;}
.p5{text-align: left;padding-right: 101px;margin-top: 17px;margin-bottom: 0px;}
.p6{text-align: left;margin-top: 38px;margin-bottom: 0px;}
.p7{text-align: left;margin-top: 35px;margin-bottom: 0px;}
.p8{text-align: left;padding-right: 116px;margin-top: 28px;margin-bottom: 0px;}
.p9{text-align: left;margin-top: 30px;margin-bottom: 0px;}
.p10{text-align: left;padding-left: 24px;padding-right: 137px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p11{text-align: left;padding-left: 24px;padding-right: 108px;margin-top: 12px;margin-bottom: 0px;text-indent: -24px;}
.p12{text-align: justify;padding-left: 24px;padding-right: 127px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p13{text-align: left;margin-top: 18px;margin-bottom: 0px;}
.p14{text-align: left;padding-left: 24px;padding-right: 129px;margin-top: 17px;margin-bottom: 0px;text-indent: -24px;}
.p15{text-align: left;padding-left: 24px;padding-right: 144px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p16{text-align: left;margin-top: 16px;margin-bottom: 0px;}
.p17{text-align: left;padding-left: 24px;padding-right: 97px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p18{text-align: left;padding-left: 79px;padding-right: 102px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p19{text-align: left;padding-left: 55px;margin-top: 18px;margin-bottom: 0px;}
.p20{text-align: left;padding-left: 79px;padding-right: 115px;margin-top: 15px;margin-bottom: 0px;text-indent: -24px;}
.p21{text-align: left;padding-right: 145px;margin-top: 43px;margin-bottom: 0px;}
.p22{text-align: left;margin-top: 28px;margin-bottom: 0px;}
.p23{text-align: left;margin-top: 41px;margin-bottom: 0px;}
.p24{text-align: left;padding-right: 109px;margin-top: 22px;margin-bottom: 0px;}
.p25{text-align: left;padding-left: 55px;margin-top: 41px;margin-bottom: 0px;}
.p26{text-align: left;padding-left: 55px;margin-top: 21px;margin-bottom: 0px;}
.p27{text-align: left;padding-left: 55px;margin-top: 20px;margin-bottom: 0px;}
.p28{text-align: left;padding-left: 55px;margin-top: 0px;margin-bottom: 0px;}
.p29{text-align: left;margin-top: 49px;margin-bottom: 0px;}
.p30{text-align: left;padding-right: 110px;margin-top: 20px;margin-bottom: 0px;}
.p31{text-align: left;padding-right: 109px;margin-top: 19px;margin-bottom: 0px;}
.p32{text-align: left;padding-right: 109px;margin-top: 26px;margin-bottom: 0px;}
.p33{text-align: left;padding-right: 103px;margin-top: 23px;margin-bottom: 0px;}
.p34{text-align: left;padding-left: 24px;padding-right: 4px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
.p35{text-align: left;padding-left: 24px;padding-right: 2px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p36{text-align: left;padding-left: 24px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p37{text-align: left;padding-left: 79px;margin-top: 0px;margin-bottom: 0px;}
.p38{text-align: left;padding-left: 79px;margin-top: 20px;margin-bottom: 0px;}
.p39{text-align: left;padding-right: 105px;margin-top: 45px;margin-bottom: 0px;}
.p40{text-align: left;margin-top: 24px;margin-bottom: 0px;}
.p41{text-align: left;padding-left: 79px;padding-right: 398px;margin-top: 39px;margin-bottom: 0px;text-indent: -24px;}
.p42{text-align: left;padding-left: 79px;padding-right: 102px;margin-top: 13px;margin-bottom: 0px;}
.p43{text-align: left;padding-left: 79px;padding-right: 289px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p44{text-align: left;padding-left: 79px;margin-top: 18px;margin-bottom: 0px;}
.p45{text-align: left;padding-left: 79px;padding-right: 113px;margin-top: 0px;margin-bottom: 0px;}
.p46{text-align: left;padding-left: 79px;padding-right: 437px;margin-top: 12px;margin-bottom: 0px;text-indent: -24px;}
.p47{text-align: left;padding-left: 79px;padding-right: 98px;margin-top: 13px;margin-bottom: 0px;}
.p48{text-align: left;padding-right: 108px;margin-top: 46px;margin-bottom: 0px;}
.p49{text-align: left;margin-top: 32px;margin-bottom: 0px;}
.p50{text-align: left;padding-left: 79px;padding-right: 104px;margin-top: 40px;margin-bottom: 0px;text-indent: -24px;}
.p51{text-align: left;padding-left: 79px;padding-right: 98px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p52{text-align: left;padding-left: 24px;padding-right: 7px;margin-top: 0px;margin-bottom: 0px;}
.p53{text-align: left;padding-left: 24px;padding-right: 6px;margin-top: 12px;margin-bottom: 0px;text-indent: -24px;}
.p54{text-align: left;padding-left: 24px;padding-right: 11px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p55{text-align: left;padding-left: 24px;padding-right: 4px;margin-top: 15px;margin-bottom: 0px;text-indent: -24px;}
.p56{text-align: left;padding-left: 24px;padding-right: 8px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p57{text-align: left;padding-left: 24px;margin-top: 12px;margin-bottom: 0px;text-indent: -24px;}
.p58{text-align: left;padding-left: 79px;padding-right: 110px;margin-top: 0px;margin-bottom: 0px;}
.p59{text-align: left;padding-left: 79px;padding-right: 110px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p60{text-align: left;margin-top: 51px;margin-bottom: 0px;}
.p61{text-align: left;padding-left: 79px;padding-right: 124px;margin-top: 39px;margin-bottom: 0px;text-indent: -24px;}
.p62{text-align: left;padding-left: 79px;padding-right: 106px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p63{text-align: left;padding-left: 79px;padding-right: 104px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p64{text-align: left;padding-left: 79px;padding-right: 125px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p65{text-align: left;padding-left: 79px;padding-right: 100px;margin-top: 13px;margin-bottom: 0px;text-indent: -24px;}
.p66{text-align: left;padding-left: 79px;padding-right: 114px;margin-top: 14px;margin-bottom: 0px;text-indent: -24px;}
.p67{text-align: left;margin-top: 45px;margin-bottom: 0px;}
.p68{text-align: left;padding-right: 99px;margin-top: 28px;margin-bottom: 0px;}
.p69{text-align: left;padding-right: 99px;margin-top: 21px;margin-bottom: 0px;}
.p70{text-align: left;padding-right: 99px;margin-top: 27px;margin-bottom: 0px;}
.p71{text-align: left;padding-right: 151px;margin-top: 23px;margin-bottom: 0px;}
.p72{text-align: left;padding-right: 102px;margin-top: 12px;margin-bottom: 0px;}
.p73{text-align: left;padding-right: 152px;margin-top: 23px;margin-bottom: 0px;}
.p74{text-align: justify;padding-right: 109px;margin-top: 23px;margin-bottom: 0px;}
.p75{text-align: left;padding-right: 121px;margin-top: 27px;margin-bottom: 0px;}
.p76{text-align: left;padding-left: 55px;margin-top: 38px;margin-bottom: 0px;}
.p77{text-align: left;padding-left: 79px;padding-right: 120px;margin-top: 16px;margin-bottom: 0px;text-indent: -24px;}
.p78{text-align: left;padding-left: 55px;margin-top: 17px;margin-bottom: 0px;}
.p79{text-align: left;padding-right: 114px;margin-top: 27px;margin-bottom: 0px;}
.p80{text-align: left;padding-right: 106px;margin-top: 26px;margin-bottom: 0px;}
.p81{text-align: left;padding-right: 104px;margin-top: 19px;margin-bottom: 0px;}
.p82{text-align: left;margin-top: 36px;margin-bottom: 0px;}
.p83{text-align: justify;padding-right: 98px;margin-top: 26px;margin-bottom: 0px;}
.p84{text-align: left;padding-right: 101px;margin-top: 22px;margin-bottom: 0px;}
.p85{text-align: left;padding-right: 101px;margin-top: 0px;margin-bottom: 0px;}
.p86{text-align: justify;padding-right: 131px;margin-top: 17px;margin-bottom: 0px;}
.p87{text-align: left;padding-left: 55px;margin-top: 44px;margin-bottom: 0px;}
.p88{text-align: left;margin-top: 50px;margin-bottom: 0px;}



</style>